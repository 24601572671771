import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";

import GridLoader from "react-spinners/GridLoader";
import EditUser from "./EditUser";
import { defaultActiveUsersColumns, userTableColumns } from "./mockdata";
import { toastOptions } from "../../../Common/ToastStyle";
import DeleteUser from "./DeleteUser";
import { RxCross2 } from "react-icons/rx";
import SubscriptionUser from "./SubscriptionUser";

const UsersMenu = () => {
  const limit = 10;
  const dispatch = useDispatch();

  const [columns, setcolumns] = useState(defaultActiveUsersColumns);

  const [modal, setmodal] = useState(false);

  const [allusers, setallusers] = useState([]);
  const [filterusers, setfilterusers] = useState([]);

  const [editusermodal, seteditusermodal] = useState(false);
  const [edituserinfo, setedituserinfo] = useState({});

  const [deleteusermodal, setdeleteusermodal] = useState(false);
  const [deleteuserid, setdeleteuserid] = useState({});

  const [subscriptionusermodal, setsubscriptionusermodal] = useState(false);
  const [subscriptionuserid, setsubscriptionuserid] = useState({});

  const [page, setpage] = useState(1);
  const [email, setemail] = useState("");

  const [loading, setloading] = useState(false);

  const getUsers = async (page) => {
    try {
      setloading(true);
      const { data } = await axios.get(`/api/user/allusers`);

      if (data.status) {
        console.log(data);
        setallusers(data.users);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Bad Happen", toastOptions);
      setloading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="container mx-auto p-[1vmax]">
      <div className="flex gap-[1vmin] flex-col overflow-x-auto ">
        <div className="flex justify-between pt-[1vmin] pr-[1vmin]">
          <div className="flex items-center">
            <div className="flex gap-[1vmin] items-center rounded-lg border-[0.4vmin] border-gray-400 w-fit bg-white p-[1vmin_1vmax] ">
              <svg
                className="w-4 h-4 text-black"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <input
                type="email"
                className="border-none outline-none"
                placeholder="Search for email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
              {email && (
                <button
                  className="bg-red-500 p-1 rounded-lg text-white font-bold"
                  onClick={() => setemail("")}
                >
                  <RxCross2 />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className={`overflow-auto flex gap-[1vmin] flex-wrap `}>
          {userTableColumns.map((column, i) => {
            return (
              <div
                key={i}
                className="flex gap-[1vmin] bg-slate-600 text-white p-[0.5vmin_1vmin] text-[1.3vmax] rounded-[1vmin]"
              >
                <input
                  type="checkbox"
                  id={column.key}
                  checked={
                    columns.find((col) => col === column.label) ? true : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newcolums = [...columns, column.label];
                      setcolumns(newcolums);
                    } else {
                      const newcolums = columns.filter(
                        (obj) => obj !== column.label
                      );
                      setcolumns(newcolums);
                    }
                  }}
                />
                <label htmlFor={column.key} className="w-max">
                  {column.label}
                </label>
              </div>
            );
          })}
        </div>
        <div className="overflow-auto">
          <table className="w-full text-black rounded-[1vmin] overflow-hidden text-left">
            <thead className="text-xs text-white uppercase  bg-gray-700 ">
              <tr>
                {userTableColumns
                  .filter((selectedcols) =>
                    columns.find((obj) => selectedcols.label === obj)
                  )
                  .map((column, i) => {
                    return (
                      <th key={i} scope="col" className="font-bold p-[1vmax] ">
                        {column.label}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {allusers
                .filter((user) => (email ? user.email.includes(email) : true))
                .map((user, i) => {
                  return (
                    <tr key={i} className="bg-white border hover:bg-blue-50 ">
                      {userTableColumns
                        .filter((selectedcols) =>
                          columns.find((obj) => selectedcols.label === obj)
                        )
                        .map((column, i) => {
                          if (column.key === "edit")
                            return (
                              <td className="p-[1vmax] flex gap-2" key={i}>
                                <button
                                  className="bg-blue-600 text-white p-[0.5vmin_1vmax] rounded-[1vmin]"
                                  onClick={() => {
                                    seteditusermodal(true);
                                    setedituserinfo(user);
                                  }}
                                >
                                  {" "}
                                  Edit{" "}
                                </button>
                                <button
                                  className="bg-red-600 text-white p-[0.5vmin_1vmax] rounded-[1vmin]"
                                  onClick={() => {
                                    setdeleteusermodal(true);
                                    setdeleteuserid(user._id);
                                  }}
                                >
                                  {" "}
                                  Delete{" "}
                                </button>
                                <button
                                  className="bg-red-600 text-white p-[0.5vmin_1vmax] rounded-[1vmin]"
                                  onClick={() => {
                                    setsubscriptionusermodal(true);
                                    setsubscriptionuserid(user._id);
                                  }}
                                >
                                  {" "}
                                  Subscription Details{" "}
                                </button>
                              </td>
                            );
                          else if (column.key === "subscription_status")
                            return (
                              <td className="p-[1vmax] " key={i}>
                                <span
                                  className={`${
                                    new Date() <=
                                    new Date(user?.subscription?.endDate)
                                      ? "bg-green-300"
                                      : "bg-red-200"
                                  } p-[1vmin_1vmax] rounded-[1vmin]`}
                                >
                                  {new Date() <=
                                  new Date(user?.subscription?.endDate)
                                    ? "Active"
                                    : "Disabled"}
                                </span>
                              </td>
                            );
                          else if (column.key === "subscription_type")
                            return (
                              <td className="p-[1vmax] " key={i}>
                                <span
                                  className={` ${
                                    user?.subscription?.plantype &&
                                    "text-green-600 font-bold"
                                  } 
                                 p-[1vmin_1vmax] rounded-[1vmin] capitalize`}
                                >
                                  {user?.subscription?.plantype
                                    ? user?.subscription?.plantype
                                    : ""}
                                </span>
                              </td>
                            );
                          else if (column.key === "subscription_start")
                            return (
                              <td key={i} className="font-bold p-[1vmax]">
                                {user?.subscription?.startDate
                                  ? `${new Date(
                                      user?.subscription?.startDate
                                    ).toDateString()} `
                                  : ""}
                              </td>
                            );
                          else if (column.key === "subscription_end")
                            return (
                              <td key={i} className="font-bold p-[1vmax]">
                                {user?.subscription?.endDate
                                  ? `${new Date(
                                      user?.subscription?.endDate
                                    ).toDateString()} `
                                  : ""}
                              </td>
                            );
                          else if (column.key === "createdAt")
                            return (
                              <td key={i} className="font-bold p-[1vmax]">
                                {user?.createdAt
                                  ? `${new Date(
                                      user?.createdAt
                                    ).toDateString()} : ${
                                      new Date(user?.createdAt)
                                        .toTimeString()
                                        .split(" ")[0]
                                    }`
                                  : ""}
                              </td>
                            );
                          else if (column.key === "topics")
                            return (
                              <td key={i} className=" p-[1vmax] w-[4vmax] ">
                                <div className="flex gap-[1vmin] overflow-auto">
                                  {user.topics &&
                                    user.topics.split(",").map((topic, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="w-max bg-green-200 p-1 rounded-[1vmin]"
                                        >
                                          {topic}
                                        </div>
                                      );
                                    })}
                                </div>
                              </td>
                            );
                          else
                            return (
                              <td className="p-[1vmax]" key={i}>
                                <div className="w-max">{user[column.key]}</div>
                              </td>
                            );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {loading && (
            <div className="bg-white w-full flex justify-center">
              <GridLoader
                color="#f21285"
                loading={loading}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
      </div>

      {editusermodal && (
        <EditUser
          seteditusermodal={seteditusermodal}
          edituserinfo={edituserinfo}
          allusers={allusers}
          setallusers={setallusers}
        />
      )}
      {deleteusermodal && (
        <DeleteUser
          setdeleteusermodal={setdeleteusermodal}
          deleteuserid={deleteuserid}
          allusers={allusers}
          setallusers={setallusers}
        />
      )}
      {subscriptionusermodal && (
        <SubscriptionUser
          setsubscriptionusermodal={setsubscriptionusermodal}
          subscriptionuserid={subscriptionuserid}
        />
      )}
    </div>
  );
};

export default UsersMenu;
