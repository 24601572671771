import { RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TiTick } from "react-icons/ti";
import { FaPlus } from "react-icons/fa6";
import { toastOptions } from "../../../Common/ToastStyle";
import GridLoader from "react-spinners/GridLoader";

const SubscriptionUser = ({ setsubscriptionusermodal, subscriptionuserid }) => {
  const [alldata, setalldata] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    axios
      .post(
        "/api/payment/alldetails",
        { userid: subscriptionuserid },
        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        console.log(data);
        setalldata(data.data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  }, []);

  return (
    <div className="fixed top-0 left-0 h-screen w-full z-10 bg-[#e5e7ebbb] flex justify-center items-center">
      <div className="bg-white w-[50%] min-h-[50%] rounded-lg p-4 pt-8 relative shadow-[0_0_10px_#00000075] flex flex-col gap-6  ">
        <h2 className="text-2xl  font-semibold leading-tight">
          Subscription History
        </h2>

        {loading ? (
          <div className="flex-grow w-full flex justify-center items-center">
            <GridLoader
              color="#f21285"
              loading={loading}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : alldata.length > 0 ? (
          <table className="rounded-md overflow-hidden ">
            <thead>
              <tr className="bg-slate-500 text-white  ">
                <th className="p-2">Date</th>
                <th>Status</th>
                <th>Plan</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {alldata.map((subscription, i) => (
                <tr className="hover:shadow-[0_0_5px_black] rounded-md border ">
                  <td className="text-center p-3">
                    {new Date(subscription.created).toDateString()}
                  </td>
                  <td
                    className={`text-center p-2 capitalize font-bold ${
                      subscription?.status === "paid"
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {subscription.status}
                  </td>
                  <td className="text-center p-2 capitalize">
                    {subscription?.plantype ? subscription.plantype : "No Data"}
                  </td>
                  <td className="text-center p-2">
                    {subscription?.startDate
                      ? new Date(subscription.startDate).toDateString()
                      : "No Data"}
                  </td>
                  <td className="text-center p-2">
                    {subscription?.endDate
                      ? new Date(subscription.endDate).toDateString()
                      : "No Data"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex-grow h-full w-full flex justify-center items-center font-bold text-xl">
            No Subscription history..
          </div>
        )}

        <button
          className="absolute top-4 right-4 "
          onClick={() => setsubscriptionusermodal(false)}
        >
          <RxCross2 className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default SubscriptionUser;
