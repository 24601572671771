import { createSlice } from '@reduxjs/toolkit';

const initSettingState = {
    verificationCode: {}
};

const settingSlice = createSlice({
    name: 'user',
    initialState: initSettingState,
    reducers: {
        SET_CODE(state, action) {
            state.verificationCode = action.payload
        },
    }
})

export const settingActions = settingSlice.actions;
export default settingSlice; 