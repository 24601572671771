import {createSlice} from '@reduxjs/toolkit'


const tableData = {
    close: false,
    awayTeam: "New York Giants",
    bookmaker: "draftkings",
    homeTeam: "Dallas Cowboys",
    market: "Moneyline",
    odd1: -1428.5714285714273,
    odd2: "+1100",
    selection1: "Home",
    selection2: "Away",
    sport: "NFL",
    time: "2023-11-12T21:28:00Z",
    __v: 0,
    _id: "654fdfe1cc158d44a549cbd4",
}


const popupReducer = createSlice({
    name: 'popup',
    initialState: tableData,
    reducers: {
        close: (state)=> {
            return {
                ...state,
                close: false
            }
        },
        insertData: (state, action) => {
            return {
                ...action.payload
            }
        }
    }
})


export const {close, insertData} = popupReducer.actions;

export default popupReducer.reducer