import { createSlice } from "@reduxjs/toolkit";

const initUserState = {
  loading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState: initUserState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
