import React, { useState } from "react";

import Offer from "./Offer";
import Guide from "./Guide";
import UsersMenu from "./Users/UsersMenu";

const Admin = () => {
  const [menu, setmenu] = useState(0);

  return (
    <div className="grid grid-cols-[20%_80%] min-h-screen ">
      <div className=" bg-[#34385e] text-white p-4 border border-[#ffffff90]">
        <ul className="flex flex-col gap-2">
          <li
            className={`p-3 rounded-lg hover:border hover:border-white ${
              menu === 0 ? "bg-[#272a47] " : "bg-[#272a4759]"
            }`}
            onClick={() => setmenu(0)}
          >
            Bookmakers
          </li>
          <li
            className={`p-3 rounded-lg hover:border hover:border-white ${
              menu === 1 ? "bg-[#272a47] " : "bg-[#272a4759]"
            }`}
            onClick={() => setmenu(1)}
          >
            Guides
          </li>
          <li
            className={`p-3 rounded-lg hover:border hover:border-white ${
              menu === 1 ? "bg-[#272a47] " : "bg-[#272a4759]"
            }`}
            onClick={() => setmenu(2)}
          >
            Users
          </li>
        </ul>
      </div>
      <div className="">
        {menu === 0 && <Offer />}
        {menu === 1 && <Guide />}
        {menu === 2 && <UsersMenu />}
      </div>
    </div>
  );
};

export default Admin;
