import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../../Common/ToastStyle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImCross } from "react-icons/im";
import GridLoader from "react-spinners/GridLoader";
import { FaPlus } from "react-icons/fa";
import { bookmakers, states } from "../../mockdata/states";

const Offer = () => {
  const [modal, setmodal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alldata, setAlldata] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);

  const userstate = useSelector((state) => state.user);

  const navigate = useNavigate();

  const getAllOffers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post("/api/bookmaker/all", {});
      // console.log(data);
      setAlldata(data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.warn("Some problem happen!", toastOptions);
    }
  };

  useEffect(() => {
    if (userstate && userstate?.user && userstate?.user?.role !== "admin") {
      navigate("/");
    }
    getAllOffers();
  }, [navigate, userstate]);

  const [info, setinfo] = useState({
    state: "Arizona",
    bookmakers: [],
  });

  const [bookMaker, setbookMaker] = useState("onexbet");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(info);

    try {
      if (!info?.state) {
        toast.warn("Please select State", toastOptions);
        return;
      } else if (info?.bookmakers.length < 1) {
        toast.warn("Please select some bookmakers", toastOptions);
        return;
      } else if (alldata.find((obj) => obj?.state === info?.state)) {
        toast.warn(
          "This State Bookmakers Already Exist ! Please Update that one",
          toastOptions
        );
        return;
      }
      const { data } = await axios.post("/api/bookmaker/create", {
        ...info,
      });
      // console.log(data);

      if (data.status) {
        setAlldata([...alldata, data?.bookmaker]);
        toast.success("State Bookmakers Added Successfully", toastOptions);
        setmodal(false);
        setinfo({
          state: "Arizona",
          bookmakers: [],
        });
        setIsUpdate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    // console.log(info);

    try {
      const { data } = await axios.post("/api/bookmaker/create", {
        ...info,
      });
      // console.log(data);

      if (data.status) {
        setAlldata((alldata) => {
          const updated = alldata.map((obj) => {
            return data.bookmaker.state === obj.state ? data.bookmaker : obj;
          });

          return updated;
        });
        toast.success("State Bookmakers Added Successfully", toastOptions);
        setmodal(false);
        setinfo({
          state: "Arizona",
          bookmakers: [],
        });
        setIsUpdate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBookmakerAdd = () => {
    if (bookMaker) {
      setinfo({
        ...info,
        bookmakers: [...info.bookmakers, bookMaker],
      });
    }
  };
  const handleBookmakerDelete = (bookmaker) => {
    if (bookMaker) {
      const updatedbookmakers = info?.bookmakers.filter(
        (bookMaker) => bookMaker !== bookmaker
      );

      setinfo({
        ...info,
        bookmakers: updatedbookmakers,
      });
      setbookMaker("");
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const { data } = await axios.post("/api/bookmaker/delete", {
        id,
      });

      setAlldata((data) => {
        const updated = data.filter((bookmaker) => id !== bookmaker._id);

        return updated;
      });
      toast.success("State bookmakers Deleted Successfully", toastOptions);
      setLoading(false);
    } catch (error) {
      toast.warn("Some Problem happen!", toastOptions);
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setmodal(false);
    setinfo({
      state: "Arizona",
      bookmakers: [],
    });
    setIsUpdate(false);
  };
  return (
    <div>
      <div className=" p-4 border border-[#ffffff90] w-full flex flex-col gap-2">
        <div className="flex gap-4 items-center justify-between">
          <div className="flex gap-2 items-center">
            <h1 className="text-2xl font-bold p-2">All State & Bookmakers</h1>
            <button
              onClick={() => setmodal(true)}
              className="flex gap-2 items-center bg-blue-500 rounded-lg p-2 h-fit text-sm text-white"
            >
              {" "}
              <FaPlus />
              Add New State
            </button>
          </div>
          <input
            type="text"
            placeholder="Search.."
            className="border-2 border-black p-2 rounded-lg"
          />
        </div>
        <table className="w-full rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-black text-white ">
              <th className="w-[20%] p-4">State</th>
              <th className="w-[60%] p-4">Bookmakers</th>
              <th className="w-[20%]  p-4">Action</th>
            </tr>
          </thead>
          <tbody>
            {alldata.map((obj) => (
              <tr className="bg-slate-100 border-b-2">
                <td className=" p-3 text-center">{obj?.state}</td>
                <td className=" p-4">
                  <ul className="flex gap-2 flex-wrap">
                    {obj?.bookmakers.map((obj) => (
                      <li className="p-2 rounded-lg bg-slate-500 text-white text-bold ">
                        {obj}
                      </li>
                    ))}
                  </ul>
                </td>
                <td className="flex gap-2 p-4 justify-center items-center">
                  <button
                    onClick={() => {
                      setinfo({
                        ...info,
                        state: obj.state,
                        bookmakers: obj.bookmakers,
                      });
                      setIsUpdate(true);
                      setmodal(true);
                    }}
                    className="flex gap-2 items-center bg-blue-500 rounded-lg p-2 px-4 h-fit text-white"
                  >
                    {" "}
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(obj._id)}
                    className="flex gap-2 items-center bg-red-500 rounded-lg p-2 px-4 h-fit text-white"
                  >
                    {" "}
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && (
          <div className="flex justify-center">
            <GridLoader
              color="#f21285"
              loading={loading}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>

      {modal && (
        <div className="fixed top-0 left-0 h-screen w-full bg-[#00000098] flex justify-center items-center">
          <div className="w-[50%] bg-[#374151] text-white p-4 rounded-lg">
            <div className="flex justify-between pr-4 w-full border-b border-slate-400">
              <div className="p-4 text-xl ">State Bookmakers</div>
              <button className="" onClick={handleModalClose}>
                <ImCross />
              </button>
            </div>
            <div className="p-4 flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <label htmlFor="" className="font-bold">
                  State
                </label>

                <select
                  name="state"
                  value={info.state}
                  onChange={(e) => setinfo({ ...info, state: e.target.value })}
                  className="p-2 text-xl text-black border outline-none  rounded-xl border-[#D4D7E3] placeholder:text-[#8897AD]"
                  required
                >
                  {states.map((state) => (
                    <option className="text-black">{state}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-3">
                <label htmlFor="" className="font-bold">
                  Bookmakers
                </label>
                <div className="flex gap-2 flex-wrap">
                  {info.bookmakers.map((bookmaker) => (
                    <span className="bg-slate-500 rounded-md p-2 pl-6 pr-4 flex gap-2">
                      {" "}
                      {bookmaker}{" "}
                      <button
                        className="text-sm"
                        onClick={() => handleBookmakerDelete(bookmaker)}
                      >
                        <ImCross />
                      </button>
                    </span>
                  ))}
                </div>
                <div className="flex gap-2">
                  <select
                    name="bookmaker"
                    value={bookMaker}
                    onChange={(e) => setbookMaker(e.target.value)}
                    className="p-2 text-xl text-black border outline-none  rounded-xl border-[#D4D7E3] placeholder:text-[#8897AD]"
                  >
                    {bookmakers.map((bookmaker) => (
                      <option className="text-black" value={bookMaker.key}>
                        {bookmaker.label}
                      </option>
                    ))}
                  </select>
                  <button
                    className="bg-[#1d4ed8] p-2 px-8 rounded-lg w-fit "
                    type="button"
                    onClick={handleBookmakerAdd}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="border-t border-slate-400 mt-2">
                {!isupdate && (
                  <button
                    onClick={handleSubmit}
                    className="bg-[#1d4ed8] mt-4 p-2 px-8 rounded-lg w-full "
                  >
                    Submit
                  </button>
                )}
                {isupdate && (
                  <button
                    onClick={handleUpdate}
                    className="bg-[#1d4ed8] mt-4 p-2 px-8 rounded-lg w-full "
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Offer;
