import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./user";
import settingSlice from "./setting";
import popupReducer from "./popupReducer";
import loadingReducer from "./loading";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    setting: settingSlice.reducer,
    popup: popupReducer,
    loading: loadingReducer,
  },
});

export default store;
