const userTableColumns = [
  { label: "Id", key: "_id" },
  { label: "Email", key: "email" },
  { label: "Name", key: "name" },
  { label: "Role", key: "role" },
  { label: "Subscription Status", key: "subscription_status" },
  { label: "Subscription Type", key: "subscription_type" },
  { label: "Subscription Start Date", key: "subscription_start" },
  { label: "Subscription End Date", key: "subscription_end" },
  { label: "Discord ID", key: "discordId" },
  { label: "Created On", key: "createdAt" },
  { label: "Action", key: "edit" },
];

const defaultActiveUsersColumns = ["Email", "Name", "Action"];

export { userTableColumns, defaultActiveUsersColumns };
