// import "./Login.css";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // <-- Import useNavigate
import axios from "axios";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/Reducers/user";
import { toast } from "react-toastify";
import { toastOptions } from "../../Common/ToastStyle";
import Rectangle_4 from "../../assets/Rectangle_4.png";
import women_tab from "../../assets/women_tab.png";

function Verify() {
  const [notification, setNotification] = useState(""); // Simplified notification state
  const [notificationType, setNotificationType] = useState("");

  const navigate = useNavigate(); // <-- Use the useNavigate hook
  const dispatch = useDispatch();

  const { state } = useLocation();
  const [otp, setotp] = useState("");

  useEffect(() => {
    if (!state?.email) {
      navigate("/");
    }
  }, [navigate, state?.email]);

  const handleVerify = async (e) => {
    e.preventDefault();

    const { email } = state;

    if (!otp.trim()) {
      toast.error("Please enter OTP", toastOptions);
      return;
    }

    try {
      const { data } = await axios.post(
        "/api/user/verify",
        {
          email,
          otp,
        },
        {
          withCredentials: true,
        }
      );

      console.log(data);

      if (data.status) {
        dispatch(userActions.CHANGE_LOGIN(true));
        dispatch(userActions.UPDATE_USER(data.user));
        setNotification("Verification successfull");
        setNotificationType("success");
        toast.success("Verification Successfull", toastOptions);
        navigate("/dashboard");
      }
    } catch (error) {
      setNotification("Error registering user: " + error.response.data.error);
      toast.error(error.response.data.error, toastOptions);

      setNotificationType("error");
    }
  };

  const handleResend = async () => {
    if (!state) {
      navigate("/");
      return toast.error("Something bad happen!", toastOptions);
    }

    const { email } = state;

    try {
      const { data } = await axios.post(
        "/api/user/resendotp",
        {
          email,
        },
        {
          withCredentials: true,
        }
      );

      if (data.status) {
        toast.success("OTP resend again !", toastOptions);
      } else if (!data.status && data?.verified) {
        toast.error("You are already verified", toastOptions);
      }
    } catch (error) {
      console.log(error);
      setNotification("Error : " + error.response.data.error);
      toast.error(error.response.data.error, toastOptions);

      setNotificationType("error");
    }
  };

  return (
    <>
      <div className="mt-11 flex flex-wrap justify-center items-center gap-5">
        <div className="w-[640px] max-sm:w-full flex flex-col justify-center items-center gap-8 px-5 py-6 bg-white">
          <div className="w-[90%] max-sm:w-full flex flex-col gap-4">
            <h2 className="font-bold text-3xl max-sm:text-4xl ">
              OTP Verification
            </h2>
            <div className="text-lg">
              {" "}
              Email sent to{" "}
              <span className="text-xl text-blue-700 font-bold">
                {" "}
                {state?.email}{" "}
              </span>
            </div>
          </div>
          <form
            className=" flex  flex-col gap-5 w-[90%] max-sm:w-full"
            onSubmit={handleVerify}
          >
            <input
              className="p-3 text-xl border outline-none  rounded-xl border-[#D4D7E3] placeholder:text-[#8897AD] h-16"
              placeholder="OTP"
              type="text"
              value={otp}
              onChange={(e) => setotp(e.target.value)}
              required
            />

            <button
              className="login-btn block font-sans bg-[#79DBAC] text-black font-bold text-xl py-3  rounded-xl"
              type="submit"
            >
              Submit
            </button>
          </form>
          <div className="flex gap-2 items-center">
            {" "}
            Don't recieve code ?{" "}
            <button
              onClick={handleResend}
              className="bg-red-300 p-2 px-4 rounded-lg hover:bg-red-400"
            >
              {" "}
              Resend OTP
            </button>{" "}
          </div>
        </div>
        <div
          className="my-4 flex shadow-2xl justify-center items-center w-[600px] h-[550px]  rounded-2xl max-lg:hidden"
          style={{
            backgroundImage: `url(${Rectangle_4})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            overflow: "hidden",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="h-[420px] w-[450px] p-5 flex border-opacity-55 border-white bg-white bg-opacity-20 rounded-3xl">
            <p className="text-[26px] text-white font-bold">
              Very good works are waiting for you Login Now!!!
            </p>
            <img src={women_tab} className="w-[270px]" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Verify;
