export const states = [
  "Arizona",
  "Colorado",
  "Illinois",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "New Jersey",
  "New York",
  "North Carolina",
  "Ohio",
  "Pennsylvania",
  "Tennessee",
  "Virginia",
  "West Virginia",
  "Wyoming",
];

export const bookmakers = [
  { id: 1, key: "onexbet", label: "1xBet", url: "https://www.1xbet.com" },
  {
    id: 2,
    key: "sport888",
    label: "888sport",
    url: "https://www.888sport.com",
  },
  {
    id: 3,
    key: "betanysports",
    label: "BetAnySports",
    url: "https://www.betanysports.com",
  },
  { id: 4, key: "betsson", label: "Betsson", url: "https://www.betsson.com" },
  {
    id: 5,
    key: "betvictor",
    label: "Bet Victor",
    url: "https://www.betvictor.com",
  },
  {
    id: 6,
    key: "everygame",
    label: "Everygame",
    url: "https://www.everygame.com",
  },
  {
    id: 7,
    key: "livescorebet_eu",
    label: "Livescorebet (EU)",
    url: "https://www.livescorebet.com",
  },
  {
    id: 8,
    key: "nordicbet",
    label: "NordicBet",
    url: "https://www.nordicbet.com",
  },
  {
    id: 9,
    key: "suprabets",
    label: "Suprabets",
    url: "https://www.suprabets.com",
  },
  {
    id: 10,
    key: "williamhill",
    label: "William Hill",
    url: "https://www.williamhill.com",
  },
];
