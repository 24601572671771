import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../../Common/ToastStyle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImCross } from "react-icons/im";
import GridLoader from "react-spinners/GridLoader";
import { FaPlus } from "react-icons/fa";
import JoditEditor from "jodit-react";

const Guide = () => {
  const editor = useRef(null);

  const [state, setstate] = useState({
    title: "",
    heading: "",
    category: "Beginner",
    subcategory: "Oddsmatcher",
    body: "",
  });

  const [modal, setmodal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isupdate, setIsUpdate] = useState(false);

  const [guides, setGuides] = useState([]);

  const userstate = useSelector((state) => state.user);

  const navigate = useNavigate();

  const getAllGuides = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post("/api/guide/all", {});
      setGuides(data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.warn("Some problem happen!", toastOptions);
    }
  };

  useEffect(() => {
    if (userstate && userstate?.user && userstate?.user?.role !== "admin") {
      navigate("/");
    }
    getAllGuides();
  }, [navigate, userstate]);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "Start typings...",
    }),
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!state?.body) {
        toast.warn("Please enter body content", toastOptions);
        return;
      }
      // else if (info?.guides.length < 1) {
      //   toast.warn("Please select some guides", toastOptions);
      //   return;
      // } else if (alldata.find((obj) => obj?.state === info?.state)) {
      //   toast.warn(
      //     "This State Guides Already Exist ! Please Update that one",
      //     toastOptions
      //   );
      //   return;
      // }
      const { data } = await axios.post("/api/guide/create", {
        state,
      });

      if (data.status) {
        setGuides([...guides, data?.guide]);
        toast.success("New guide added successfully", toastOptions);
        setmodal(false);
        setstate({
          title: "",
          heading: "",
          category: "Beginner",
          subcategory: "Oddsmatcher",
          body: "",
        });
        setIsUpdate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { data } = await axios.post("/api/guide/update", {
        state,
      });

      if (data.status) {
        setGuides((guides) => {
          const updated = guides.map((obj) => {
            return data.guide._id.toString() === obj._id.toString()
              ? data.guide
              : obj;
          });

          return updated;
        });
        toast.success("State Guides Updated Successfully", toastOptions);
        setmodal(false);
        setstate({
          title: "",
          heading: "",
          category: "Beginner",
          subcategory: "Oddsmatcher",
          body: "",
        });
        setLoading(false);
        setIsUpdate(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const { data } = await axios.post("/api/guide/delete", {
        id,
      });

      setGuides((data) => {
        const updated = data.filter((guide) => id !== guide._id);

        return updated;
      });
      toast.success("State guides Deleted Successfully", toastOptions);
      setLoading(false);
    } catch (error) {
      toast.warn("Some Problem happen!", toastOptions);
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setmodal(false);
    setstate({
      title: "",
      heading: "",
      category: "Beginner",
      subcategory: "Oddsmatcher",
      body: "",
    });
    setIsUpdate(false);
  };

  return (
    <div>
      <div className=" p-4 border border-[#ffffff90] w-full flex flex-col gap-2">
        <div className="flex gap-4 items-center justify-between">
          <div className="flex gap-2 items-center">
            <h1 className="text-2xl font-bold p-2">All Guides</h1>
            <button
              onClick={() => setmodal(true)}
              className="flex gap-2 items-center bg-blue-500 rounded-lg p-2 h-fit text-sm text-white"
            >
              {" "}
              <FaPlus />
              Add New Guide
            </button>
          </div>
          <input
            type="text"
            placeholder="Search.."
            className="border-2 border-black p-2 rounded-lg"
          />
        </div>

        <table className="w-full rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-black text-white ">
              <th className="p-4"></th>
              <th className="p-4">Title</th>
              <th className="p-4">Heading</th>
              <th className="p-4">Category</th>
              <th className="p-4">Sub-Category</th>
              <th className="p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {guides.map((obj, i) => (
              <tr className="bg-slate-100 border-b-2">
                <td className="p-3 text-center">{i + 1}</td>
                <td className="p-3 text-center">{obj?.title}</td>
                <td className="p-3 text-center">{obj?.heading}</td>
                <td className="p-3 text-center">{obj?.category}</td>
                <td className="p-3 text-center">{obj?.subcategory}</td>
                <td className="flex gap-2 p-4 justify-center items-center">
                  <button
                    onClick={() => {
                      setstate({
                        ...obj,
                      });
                      setIsUpdate(true);
                      setmodal(true);
                    }}
                    className="flex gap-2 items-center bg-blue-500 rounded-lg p-2 px-4 h-fit text-white"
                  >
                    {" "}
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(obj._id)}
                    className="flex gap-2 items-center bg-red-500 rounded-lg p-2 px-4 h-fit text-white"
                  >
                    {" "}
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {loading && (
          <div className="flex justify-center">
            <GridLoader
              color="#f21285"
              loading={loading}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
      {modal && (
        <div className="fixed top-0 left-0 h-screen w-full bg-[#00000098] flex justify-center items-center z-50">
          <div className="w-[70%] z-50 bg-[#374151] text-white p-4 rounded-lg flex flex-col gap-4">
            <div className="flex justify-between pr-4 w-full border-b border-slate-400">
              <div className="p-4 text-xl">Guide</div>
              <button className="" onClick={handleModalClose}>
                <ImCross />
              </button>
            </div>

            <div className="flex flex-col gap-3">
              <input
                type="text"
                className="p-2 rounded-lg text-black"
                placeholder="Title"
                value={state.title}
                onChange={(e) => setstate({ ...state, title: e.target.value })}
              />
              <input
                type="text"
                className="p-2 rounded-lg text-black"
                placeholder="Heading"
                value={state.heading}
                onChange={(e) =>
                  setstate({ ...state, heading: e.target.value })
                }
              />
              <div className="grid grid-cols-2 gap-2 ">
                <div className="flex flex-col gap-2">
                  <label htmlFor="category"> Category</label>
                  <select
                    name="category"
                    id="category"
                    className="text-black p-2 rounded-lg"
                    value={state.category}
                    onChange={(e) =>
                      setstate({ ...state, category: e.target.value })
                    }
                  >
                    <option value="Beginner">Beginner</option>
                    <option value="General">General</option>
                    <option value="Product">Product</option>
                  </select>
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="">Sub-Category</label>
                  <select
                    name=""
                    id=""
                    className="text-black p-2 rounded-lg"
                    value={state.subcategory}
                    onChange={(e) =>
                      setstate({ ...state, subcategory: e.target.value })
                    }
                  >
                    <option value="Oddsmatcher">Oddsmatcher</option>
                    <option value="Sports Offers">Sports Guides</option>
                    <option value="Casino Offers">Casino Guides</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-grow h-fit text-black rounded-lg max-h-[40vh] overflow-scroll">
              <JoditEditor
                ref={editor}
                value={state.body}
                config={config}
                tabIndex={1}
                onBlur={(newContent) =>
                  setstate({ ...state, body: newContent })
                }
                onChange={(newContent) =>
                  setstate({ ...state, body: newContent })
                }
              />
            </div>
            <div className="border-t border-slate-400 mt-2">
              {!isupdate && (
                <button
                  onClick={handleSubmit}
                  className="bg-[#1d4ed8] mt-4 p-2 px-8 rounded-lg w-full "
                >
                  Submit
                </button>
              )}
              {isupdate && (
                <button
                  onClick={handleUpdate}
                  className="bg-[#1d4ed8] mt-4 p-2 px-8 rounded-lg w-full "
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Guide;
