import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./guidepage.css";
import axios from "axios";
import { toast } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";

import GridLoader from "react-spinners/GridLoader";
import { toastOptions } from "../../../../Common/ToastStyle";

const GuidePage = () => {
  const { id } = useParams();

  const [details, setdetails] = useState(null);

  const navigate = useNavigate();

  const getGuideDetails = async () => {
    try {
      const { data } = await axios.post(`/api/guide/details/${id}`);
      console.log(data);
      if (data.status) {
        setdetails(data.guide);
      } else {
        navigate("/dashboard");
        toast.error(data.message, toastOptions);
      }
    } catch (error) {
      console.log(error);
      toast.success("Some Problem!", toastOptions);
    }
  };

  useEffect(() => {
    getGuideDetails();
  }, []);

  if (!details) {
    return (
      <div className="bg-[#ffffffba] w-full h-screen flex justify-center items-center">
        <GridLoader
          color="#f21285"
          loading={true}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return (
    <>
      <div className="rounded-md shadow-xl p-[3vmax] flex flex-col gap-[1vmax] bg-[#eff4f8] min-h-screen">
        <div>
          <Link
            to="/guides"
            className="flex gap-2 items-center bg-blue-900 text-white p-3 px-4 rounded-lg w-fit"
          >
            {" "}
            <IoIosArrowBack />
            Go to All Guides
          </Link>
        </div>
        <div className=""></div>
        <div className="grid grid-cols-[65%_30%]  md:grid-cols-[70%_30%] xl:grid-cols-[75%_25%] items-center gap-[2vmax]">
          <h1 className="text-black capitalize font-bold md:text-[2.5vmax]">
            Guide : {details.title}
          </h1>
        </div>

        <div className="rounded-[1vmin] overflow-hidden">
          <div className="bg-[#0b1547] p-4 text-white text-xl">
            {details?.heading}
          </div>

          <div className="p-[1vmax] bg-white">
            <span
              className=""
              dangerouslySetInnerHTML={{ __html: details.body }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GuidePage;
